import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './login/login';
// import Register from './login/newregister';
import AdminTable from './admin/AdminTable';
import BlogsEdit from './admin/BlogsEdit';
import CourseEdit from './admin/CourseEdit';
import DetailsEdit from './admin/DetailsEdit';
import FaqEdit from './admin/FaqEdit';
import FooterContentEdit from './admin/FooterContentEdit';
import FooterEdit from './admin/FooterEdit';
import HomeEdit from './admin/HomeEdit';
import UserList from './admin/UserList';
import LogoEdit from './admin/LogoEdit';
import Main from './admin/Main';
import NavbarEdit from './admin/NavbarEdit';
import ProfileEdit from './admin/ProfileEdit';
import AdminProfile from './admin/AdminProfile';
import UserDetailsEdit from './admin/UserDetailsEdit';
import ServiceList from './admin/ServiceList';
import CarList from './admin/CarBrandList';
import CarData from './admin/CarData';
import QuestionnariesList from './admin/QuestionnariesList';
import QuestionnariesEdit from './admin/QuestionnariesEdit';
import OrderList from './admin/OrderList';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/register" element={<Register />} /> */}

          <Route path="/" element={<Main />} />
          <Route path="/table" element={<AdminTable />} />
          <Route path="/logo" element={<LogoEdit />} />
          <Route path="/navedit" element={<NavbarEdit />} />
          <Route path="/footeredit" element={<FooterEdit />} />
          <Route path="/homeedit" element={<HomeEdit />} />
          <Route path="/userlist" element={<UserList />} />
          <Route path="/detailsedit" element={<DetailsEdit />} />
          <Route path="/blogsedit" element={<BlogsEdit />} />
          <Route path="/courseedit" element={<CourseEdit />} />
          <Route path="/footerCedit" element={<FooterContentEdit />} />
          <Route path="/faqedit" element={<FaqEdit />} />
          <Route path="/profileedit" element={<ProfileEdit />} />
          <Route exact path="/profile" element={<AdminProfile />} /> 
          <Route exact path="/user-details/:userId" element={<UserDetailsEdit />} /> 
          <Route exact path="/service-list" element={<ServiceList />} />
          <Route exact path="/car-list" element={<CarList />} />  
          <Route exact path="/car-data" element={<CarData />} />
          <Route exact path="/questionnaries-list" element={<QuestionnariesList />} />
          <Route exact path="/troubleshoot-questionnaries-edit/:id" element={<QuestionnariesEdit />} />
          <Route exact path="/order-list" element={<OrderList />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;