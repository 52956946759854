import React, { useEffect, useState } from 'react';
import SideBar from './SideBar';
import axios from 'axios';
import Navbar from "./Navbar";
import { useAuth } from '../AuthContext';

const Main = () => {
  const { apipath } = useAuth();
  const [userCount, setUserCount] = useState(0);
  const [showPopup, setShowPopup] = useState(true);
  const [paymentHistory, setPaymentHistory] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const sortedPaymentHistory = [...paymentHistory].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  const totalPages = Math.ceil(paymentHistory.length / itemsPerPage);
  const currentItems = sortedPaymentHistory.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get(`${apipath}/users/details`);
        setUserCount(response.data.userCount);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };

    fetchUserCount();
  }, [apipath]);

  // Fetch payment history from backend
  useEffect(() => {
    const fetchPaymentHistory = async () => {
      try {
        const response = await axios.get(`${apipath}/order/getall`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('admintoken')}`
          },
        });
        setPaymentHistory(response.data.orders);
      } catch (error) {
        console.error("Error fetching order history:", error);
      }
    };
    fetchPaymentHistory();
  }, [apipath]);

  // Calculate totals
  const today = new Date().toISOString().split('T')[0];
  const totalOverallOrderAmount = paymentHistory.reduce(
    (total, order) => total + (order.orderAmount || 0),
    0
  );
  const totalTodaysOrderAmount = paymentHistory.reduce((total, order) => {
    const bidDate = order.bidacceptDate ? order.bidacceptDate.split('T')[0] : '';
    return bidDate === today ? total + (order.orderAmount || 0) : total;
  }, 0);


  return (
    <>

      <SideBar />
      <Navbar />


      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="admin-banner">
          <div className="row">
            {showPopup && (
              <div className="col-lg-12 dash-up">
                <div className="dash-strip">
                  <div>
                    <h3>Congrats!! you can now modify your application</h3>
                    <p>
                      Keep all the information up to date with minimal effort
                    </p>
                  </div>
                  <div>
                    <i
                      className="bi bi-x"
                      onClick={() => setShowPopup(false)}
                    ></i>
                  </div>
                </div>
                <div className="over"></div>
              </div>
            )}
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-person"></i> Total Registered Users :
                </h2>
                <ul className="overlapping-avatar">
                  <li><span>{userCount}</span></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-person"></i> Total Registered Services :
                </h2>
                <ul className="overlapping-avatar">
                  <li><span>{userCount}</span></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                <i className="bi bi-bookmark"></i> Total Completed Orders:
                </h2>
                <ul className="overlapping-avatar">
                  <li><span>{userCount}</span></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="card p-4 shadow-sm">
              <div className="row text-center">
                <h3>Total Order Summary</h3>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-6 text-center">
                  <h4>
                    <i className="bi bi-graph-up-arrow"></i> Total Overall Order Amount:
                    <span className="text-success"> ${totalOverallOrderAmount.toFixed(2)}</span>
                  </h4>
                </div>
                <div className="col-lg-6 text-center">
                  <h4>
                    <i className="bi bi-graph-up-arrow"></i> Total today's order Amount:
                    <span className="text-success"> ${totalTodaysOrderAmount.toFixed(2)}</span>
                  </h4>
                </div>
              </div>
              <hr />
              <div className="table-responsive mt-4">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th className='text-center'>S.No.</th>
                      <th className='text-center'>Provider Shop Name</th>
                      <th className='text-center'>Service Name</th>
                      <th className='text-center'>Order Amount</th>
                      <th className='text-center'>Order Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((transaction, index) => (
                        <tr key={transaction.id}>
                          <td className='text-center'>
                            {(currentPage - 1) * itemsPerPage + index + 1}
                          </td>
                          <td className='text-center'>{transaction.shopname}</td>
                          <td className='text-center'>{transaction.servicename}</td>
                          <td className='text-center'>${transaction.orderAmount}</td>
                          <td className='text-center'>{transaction.orderstatus}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center py-4">
                          No transactions found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Pagination controls */}
              <div className="d-flex justify-content-between align-items-center p-3">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className={`btn ${currentPage === 1 ? "btn-secondary disabled" : "btn-primary"
                    }`}
                >
                  Previous
                </button>
                <span className="text-muted">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className={`btn ${currentPage === totalPages ? "btn-secondary disabled" : "btn-primary"
                    }`}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Main