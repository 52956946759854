import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import Navbar from './Navbar';
import axios from 'axios';
import iziToast from "izitoast";
import { useAuth } from '../AuthContext';

const OrderList = () => {
    const [orders, setOrders] = useState([]);
    const dataTableRef = useRef();
    const { apipath } = useAuth();

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${apipath}/order/getall`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('admintoken')}`
                    }
                });
                setOrders(response.data.orders);
            } catch (error) {
                iziToast.error({
                    title: 'Error',
                    message: 'An error occurred while fetching data',
                    position: 'topCenter'
                });
            }
        };
        fetchOrders();
    }, [apipath]);

    useEffect(() => {
        if (orders.length > 0) {
            const table = $(dataTableRef.current).DataTable();
            table.destroy(); // Destroy existing instance

            $(dataTableRef.current).DataTable({
                paging: true,
                searching: true,
                responsive: true,
            });
        }
    }, [orders]);

    return (
        <>
            <SideBar />
            <Navbar />

            <section className='home'>
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className='bi bi-menu'></i>
                </div>

                <div className="container">
                    <div className="row foot-tablerow">
                        <div className='test-div'>
                            <h5>Booking Management</h5>
                        </div>
                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    <thead>
                                        <tr>
                                            <th className='col-3 text-center'>S. No.</th>
                                            <th className='col-3 text-center'>Shop Name</th>
                                            <th className='col-3 text-center'>Service Name</th>
                                            <th className='col-3 text-center'>Status</th>
                                            {/* <th className='col-3 text-center'>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.length > 0 ? (
                                            orders.map((order, index) => (
                                                <tr key={order.id}>
                                                    <td className='col-3 text-center'>{index + 1}</td>
                                                    <td className='col-3 text-center'>{order.shopname}</td>
                                                    <td className='col-3 text-center'>{order.servicename}</td>
                                                    <td className='col-3 text-center'>{order.orderstatus}</td>
                                                    {/* <td className='col-3 text-center'>
                                                        <i
                                                            className="fa-regular fa-pen-to-square"
                                                            onClick={() => {
                                                                handleEdit(order);
                                                            }}
                                                        ></i>&nbsp;
                                                        <i
                                                            className="fa-solid fa-trash"
                                                            onClick={() => {
                                                                setOptionToDelete(order._id);
                                                                setShowDeleteModal(true);
                                                            }}
                                                            style={{ cursor: 'pointer', color: 'red' }}
                                                        ></i>
                                                    </td> */}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3" className="text-center">
                                                    No orders available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OrderList;